<template>
  <div class="flex-box">
    <!-- <vue-qr class="qr-code" :logoSrc="imageUrl" :text="qrCodeUrl" :size="150" :margin="10"/> -->
    <vue-qr class="qr-code" :text="url" :size="size" :margin="10"/>
  </div>
</template>

<script>
import vueQr from 'vue-qr'; // vue2.0
// import vueQr from 'vue-qr/src/packages/vue-qr.vue'; // vue3.0 (support vite)

export default {
  components: { vueQr },
  props: ['url','size'],
  data() {
    return {
    };
  },
  mounted(){
  },
  methods: {}
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.qr-code {
  background-color: #fff;
}
</style>
